import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/Modal";

import "./LoginModal.scss";

function LoginModal() {
  const { t } = useTranslation();

  return (
    <Modal className="login-modal" id="login">
      <h1>{t("field.login")}</h1>
      <form className="login-modal__form">
        <div className="control">
          <label>{t("field.email")}:</label>
          <input className="login-modal__form-input" type="text" />
        </div>
        <div className="control">
          <label>{t("field.password")}:</label>
          <input className="login-modal__form-input" type="password" />
        </div>
        <button type="submit">{t("field.login")}</button>
      </form>
    </Modal>
  );
}

export default LoginModal;
