import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const ru = require("./translation/ru.json");
const en = require("./translation/en.json");

const resources = {
  en: { translation: en },
  ru: { translation: ru },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ru",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
