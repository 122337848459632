import React from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import "./Modal.scss";
import { hideModal } from "../../redux/actions/modal";

function Modal({ children, className, id }) {
  const dispatch = useDispatch();
  const openModals = useSelector((state) => state.modal.show);

  const isShow = openModals.includes(id);

  return (
    <div className={cn("modal", className, { "modal--open": isShow })}>
      <div
        className="modal__overlay"
        onClick={() => dispatch(hideModal(id))}
      ></div>
      <div className="modal__body">
        <div
          className="modal__close"
          onClick={() => dispatch(hideModal(id))}
        ></div>
        {children}
      </div>
    </div>
  );
}

export default Modal;
