import { langTypes } from "../types/lang";

const initialState = {
  lang: "ru",
};

const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case langTypes.setRu:
      return {
        ...state,
        lang: "ru",
      };
    case langTypes.setEng:
      return {
        ...state,
        lang: "eng",
      };
    default:
      return state;
  }
};

export default langReducer;
