import "./normalize.scss";
import "./app.scss";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { AboutUs } from "./pages/AboutUs";
import { Route, Switch } from "react-router";
import Services from "./pages/Services/Services";
import { Contact } from "./pages/Contact";
import ApplicationForm from "./pages/ApplicationForm/ApplicationForm";
import Questions from "./pages/Questions/Questions";
import { HomeConsumer, HomeMerchant } from "./pages/Home";
import { useSelector } from "react-redux";
import { Login } from "./pages/Login";
import { TermsOfRervice } from "./pages/TremsOfService";
import { PrivacyPolicy } from "./pages/PrivacyProlicy";
import { AcceptPolicy } from "./pages/AcceptPolicy";
import { Copyright } from "./pages/Copyright";

function App() {
  const isMerchantMode = useSelector((state) => state.mode.isMerchant);

  return (
    <div className="App">
      <Header />
      <Switch>
        {isMerchantMode ? (
          <>
            <Route exact path="/" component={HomeMerchant} />
            <Route path="/about" component={AboutUs} />
            <Route path="/service" component={Services} />
            <Route path="/contacts" component={Contact} />
            <Route path="/app-form" component={ApplicationForm} />
            <Route path="/questions" component={Questions} />
            <Route path="/terms" component={TermsOfRervice} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/accept" component={AcceptPolicy} />
            <Route path="/copyright" component={Copyright} />
            <Route path="/login" component={Login} />
          </>
        ) : (
          <>
            <Route exact path="/" component={HomeConsumer} />
            <Route path="/about" component={AboutUs} />
            <Route path="/contacts" component={Contact} />
            <Route path="/questions" component={Questions} />
            <Route path="/terms" component={TermsOfRervice} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/copyright" component={Copyright} />
          </>
        )}
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
