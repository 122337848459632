import React from "react";
import { Page } from "../../components/Page";

function Copyrigth() {
  return (
    <Page title="Copyright/DMCA Policy">
      Subbill respects the intellectual-property rights of others and expects
      its clients to do the same. Each client is responsible for making sure
      that the materials on their website does not infringe any copyright,
      trademark, trade secret, or other intellectual-property right of any
      person.
      <br />
      <br />
      The sale of counterfeit goods and copyright piracy are issues Subbill
      takes seriously, and Subbill has developed this policy to address this
      problem. Because rights holders are in the best position to identify and
      evaluate infringement of their intellectual property, without specific and
      reliable notices from rights holders, Subbill lacks the knowledge and
      capability to identify and address infringement. Accordingly, Subbill
      expects intellectual-property holders to be accurate in demonstrating
      infringement of their copyrights and trademark rights and to target only
      infringing conduct.
      <br />
      <br />
      Subbill does not host or control the content on third-party websites
      (i.e., client websites) and is not able to remove websites or their
      content from the Internet. Nor can Subbill engage in extensive or
      definitive fact finding to determine a particular party's
      intellectual-property rights. Subbill prohibits websites that are
      principally dedicated to selling counterfeit goods or engaging in
      copyright piracy and have no substantial non-infringing uses from using
      Subbill.
      <br />
      <br />
      If you believe that a client's website is infringing your
      intellectual-property rights, you may submit written notice to Subbill's
      Copyright Agent (designated below). In accordance with the Online
      Copyright Infringement Liability Limitation Act of the Digital Millennium
      Copyright Act (17 U.S.C. § 512) (“DMCA”), the written notice (the “DMCA
      Notice”) must include substantially the following:
      <ul>
        <li>Your physical or electronic signature.</li>
        <li>
          Identification of the copyright work you believe to have been
          infringed or, if the claim involves multiple works, a representative
          list of the works.
        </li>
        <li>
          Identification of the material you believe to be infringing in a
          sufficiently precise manner to allow location of that material.
        </li>
        <li>
          Adequate information by which Subbill can contact you (including your
          name, postal address, telephone number, and, if available, email
          address).
        </li>
        <li>
          A statement that you have a good faith belief that use of the
          copyrighted material is not authorized by the copyright owner, its
          agent, or the law.
        </li>
        <li>
          A statement that the information in the written notice is accurate.
        </li>
        <li>
          A statement, under penalty of perjury, that you are authorized to act
          on behalf of the copyright owner.
        </li>
      </ul>
      <br />
      <br />
      If you fail to comply with all of the requirements of Section 512(c)(3) of
      the DMCA, your DMCA Notice may not be effective.
      <br />
      <br />
      Please be aware that if you knowingly materially misrepresent that
      material or activity is infringing your copyright, you may be held liable
      for damages (including costs and attorneys' fees) under Section 512(f) of
      the DMCA.
      <br />
      <br />
      It is Subbill's policy in appropriate circumstances to disable or
      terminate the accounts of clients who are repeat infringers in accordance
      with Subbill's Repeat Infringer Policy.
    </Page>
  );
}

export default Copyrigth;
