import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Page } from "../../components/Page";
import Support from "./assets/support.svg";
import "./Questions.scss";

function Questions() {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();

  return (
    <Page title={t("questions.title")} className="questions">
      <h2 className="page__block-title" style={{ color: "#00F0FF" }}>
        {t("questions.text_1.title")}
      </h2>
      <p className="page__block-text">{t("questions.text_1.text_1")}</p>
      <br />
      <br />
      <p className="page__block-text">{t("questions.text_1.text_2")}</p>
      <br />
      <br />
      <p className="page__block-text">{t("questions.text_1.text_3")}</p>
      <h2 className="page__block-title" style={{ color: "#00F0FF" }}>
        {t("questions.text_2.title")}
      </h2>
      <p className="page__block-text">
        {t("questions.text_2.text_1")}
        <br />
        <br />
        {t("questions.text_2.text_2")}
        <br />
        <br />
        {t("questions.text_2.text_3")}

        <br />
        <br />
        {t("questions.text_2.text_4")}
      </p>
      <h2 className="page__block-title" style={{ color: "#00F0FF" }}>
        {t("questions.text_3.title")}
      </h2>
      <p className="page__block-text">
        {t("questions.text_3.text_1")}

        <br />
        <br />
        {t("questions.text_3.text_2")}

        <br />
        <br />

        {t("questions.text_3.text_3")}

        <br />
        <br />

        {t("questions.text_3.text_4")}
        <br />
        <br />
      </p>
      <form>
        <div className="row">
          <div className="col-md-8 order-md-1 order-sm-2 order-2">
            <div className="row">
              <div className="col-md-6 control">
                <label htmlFor="email">{t("field.email")}:</label>
                <input ref={register({})} name="email" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="problem">{t("field.problem")}:</label>
                <select ref={register({})} name="problem">
                  <option value={t("questions.select_1")}>
                    {t("questions.select_1")}
                  </option>
                  <option value={t("questions.select_2")}>
                    {t("questions.select_2")}
                  </option>
                  <option value={t("questions.select_3")}>
                    {t("questions.select_3")}
                  </option>
                </select>
              </div>
              <div className="col-md-6 control">
                <label htmlFor="url">{t("field.site_url")}:</label>
                <input ref={register({})} name="url" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="fullName">{t("field.username")}:</label>
                <input ref={register({})} name="fullName" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="surname">{t("field.last_name")}:</label>
                <input ref={register({})} name="surname" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="name">{t("field.first_name")}:</label>
                <input ref={register({})} name="name" type="text" />
              </div>
            </div>
          </div>
          <div className="col-md-4 order-md-2 order-sm-1 order-1 timing">
            <h1>{t("questions.timing.title")}</h1>
            <h2>{t("questions.timing.text")}</h2>
            <img src={Support} alt="" />
          </div>
        </div>
        <div className="row">
          <div
            className="col-lg-9 col-xs-12 control"
            style={{ marginBottom: 0 }}
          >
            <label htmlFor="message">{t("field.description")}:</label>
            <textarea name="message" />
          </div>
          <div className="col-lg-2 col-md-3" style={{ paddingTop: 40 }}>
            <button type="submit" style={{ height: "100%", width: "190px" }}>
              {t("field.submit")}
            </button>
          </div>
        </div>
      </form>
    </Page>
  );
}

export default Questions;
