import React from "react";

import { Page } from "../../components/Page";

function TermsOfRervice() {
  return (
    <Page title="Client Terms and Conditions">
      <p className="page__block-text">
        Please read the following terms and conditions of the service. If you do
        not want to be bound by these terms and conditions, do not accept the
        service. We want the terms and conditions to meet your needs and the
        needs of our other clients, and we expect that these terms and
        conditions will evolve over time. Your continued use of the service will
        constitute acceptance of any changes to the terms and conditions. This
        agreement becomes effective on the date it is accepted by Subbill.
      </p>
      <h2 className="page__block-title">1. Definitions</h2>{" "}
      <p className="page__block-text">
        When used in this agreement, the following definitions apply:
        <br />
        <br />
        A. ACH: Automated Clearing House, a service provided through the United
        States Federal Reserve banking system that allows customers to convert
        paper financial instruments to electronic form.
        <br />
        B. ACH Returned Item: Any customer's check that is returned through the
        ACH system.
        <br />
        C. Chargeback: A charge to the customer that is reversed by the credit
        card issuer.
        <br />
        D. Client: An entry or individual contracting with Subbill in order to
        utilize Subbill's automated financial transaction processing and
        ancillary related services.
        <br />
        E. Customer: Anyone trying to access a Client's venue with a membership
        ID issued through Subbill's e-ticketing and access management software.
        <br />
        F. Customer Charges: Amounts to be charged to the customer.
        <br />
        G. Database: Any and all information received from Client and/or
        Customer which Subbill keeps in an electronic format for Subbill's use.
        This may include at Subbill's sole discretion a file containing only the
        Client's information and/or a universal file containing all of Subbill's
        Clients' information.
        <br />
        H. E-Ticketing and /Site Access Management System: Those services
        provided by Subbill under the terms of this agreement to facilitate
        customer access to your venue.
        <br />
        I. Membership IDs: A unique code assigned to a customer through
        Subbill's e-ticketing system that permits access to your restricted
        venue.
        <br />
        J. New Client: A client of Subbill's including you and/or your company,
        if neither you nor your company has ever been a client of Subbill.
        <br />
        K. Online Check: A system that allows customer to utilize bank accounts
        without providing a physical instrument to debit the customer's account
        for the purchase of an e-ticket.
        <br />
        L. Payment Form: The payment mechanism, i.e. credit card, on-line check,
        900 number, etc., a customer utilizes in purchasing an e-ticket to gain
        access to a Client's venue.
        <br />
        M. Penalty: Any fine, fee, assessment or other charge levied against
        Subbill by the card associations, Merchant Bank, any state or federal
        Regulatory Agency, or ACH system that is in addition to the normal fees
        charged by those entities.
        <br />
        N. Refund: Any payment from your customer that is reversed by Subbill or
        you.
        <br />
        O. Security Deposit: A percentage of the value of each transaction that
        is withheld from your gross amount as determined by Subbill and/or the
        Merchant Bank. The amount of security deposit will vary from time to
        time and payment form to payment form as will the period of time the
        security deposit is held for.
        <br />
        P. Tangible Goods: Hard goods that are purchased by consumers through
        Subbill and provided to consumers via direct shipment from Client.in
        accordance with Exhibit A and Exhibit B attached hereto.
        <br />
        Q. Venue: A Client's collection of website pages and content that create
        the Client's entertainment arena. R. Website: The website(s) owned by
        you that utilize Subbill's e-ticketing system and site access management
        tools.
        <br />
        S. Your: The Client and owner and/or creator of the website and venue.
      </p>
      <br />
      <br />
      <h2 className="page__block-title">2. Website Pricing Options</h2>
      <p className="page__block-text">
        The minimum price that you can charge is $2.95 per transaction. The
        maximum is $99.99 per transaction. Any exceptions must be applied for in
        writing to Subbill, and may or may not be accepted based solely on
        Subbill's discretion.
      </p>
      <br />
      <br />
      <h2 className="page__block-title">3. Services</h2>
      <br />
      <p className="page__block-text">
        The services covered by this agreement include Subbill's e-ticketing and
        site access management tools. In addition, Subbill provides some
        services such as client affiliate program software and transaction
        reporting and tracking. Subbill reserves the right to stop allowing you
        to use these added services if in Subbill's judgment they are
        contributing to violations of Subbill's Acceptable Use Policies (AUP) or
        high volumes of chargebacks, ACH returned items, refunds, or customer
        complaints. Subbill reserves the right to begin charging for these
        additional services upon thirty (30) days written notice to the contact
        information that we have on file for you, and Subbill also reserves the
        right to offer new services that are not covered by this agreement, and
        to charge you extra if you choose to use them.
        <br />
        <br /> In order to provide the highest level of service to all Subbill's
        clients, data provided by customers for venue access will be combined
        with data from all Subbill's' client customers. The combined database
        will be used for risk analysis of your and all other clients'
        transactions. Ownership of the combined database remains with Subbill.
        Credit card information from Subbill's database will not be provided to
        you or any other party during this agreement or upon termination of this
        agreement. However, Subbill reserves the right to transfer the database
        in the event of a change in Subbill's ownership. You are responsible for
        maintaining a record of all user id information for your own use as well
        as for backup in the event of a system failure that requires
        restoration.
        <br />
        <br />
        Any unauthorized attempt to gain access to Subbill's database or systems
        by you, or anyone directly or indirectly associated or related to you,
        however remotely, will constitute a material breach of this agreement.
        In the event of any such attempt to gain access, Subbill may immediately
        terminate this agreement, and you will immediately return to Subbill all
        copies of any information obtained by this access.
      </p>
      <br />
      <br />
      <h2 className="page__block-title">4. Tangible Goods Sales</h2>
      <p>
        Subbill agrees to permit Client to submit ecommerce transactions for
        tangible goods, and Client agrees to submit transactions for the sale of
        tangible goods subject to the terms of this Agreement, the attached
        policies (Exhibit B) and the attached addition to Subbill's standard AUP
        (Exhibit A).
        <br />
        Client acknowledges and agrees that it will not use Subbill's systems
        for any purpose that is illegal.
      </p>
      <br />
      <h2 className="page__block-title">5. Fees</h2>
      <p>
        Subbill shall charge, and Client agrees to pay, the fees set forth in
        Schedule 1 attached hereto for the services provided by Subbill. Subbill
        shall have the right to change the fees stated herein upon providing
        notice to client at least 10 days prior to the effective date of such
        changes. Subbill will deduct this fee from your funds before payment to
        you.
        <br />
        <br />
        In addition to the fee, Subbill shall hold a security deposit for a
        minimum of six months as set forth in Schedule 2 attached hereto.
        Subbill may increase its security deposit requirements, at any time, if
        in Subbill's sole discretion it is necessary to cover potential refunds,
        ACH returned items, chargebacks, customer disputes, and/ or fines or
        penalties imposed directly or indirectly on Subbill by its acquiring
        bank, the card associations, regulatory agencies, or the ACH system.
        <br />
        <br />
        In the event Client utilizes both transaction processing and hosting
        services from any affiliated entities, Client hereby authorizes and
        agrees to allow the funds of their transaction processing reserve to be
        applied, wholly or in part, to their delinquent hosting account. This
        offset is intended to protect the Client from discontinuation of hosting
        services for unpaid balances on account.
        <br />
        <br />
        If Subbill is penalized by its acquiring bank, a card association, or
        NACHA for chargebacks, refunds, or returned checks arising from
        Subbill's processing transactions for your website(s), Subbill may at
        any time and at its discretion pass through to you actual amount of the
        penalty per item. Subbill's waiver of this paragraph for any period of
        time does not constitute forfeiture of Subbill's right to impose the
        application of the penalties retroactively or for that period of time.
        You acknowledge that the card associations and regulatory agencies may
        apply chargebacks and fines retroactively and that Subbill must retain
        that right as well. In the event a flat amount penalty is assessed
        against Subbill as opposed to a per transaction penalty, Subbill may at
        its sole discretion prorate the flat amount among those clients that
        Subbill at its sole discretion deems to have been the cause of the
        penalty.
        <br />
        <br />
      </p>
      <h2 className="page__block-title">6. Payment</h2>
      <p>
        In order to receive payments a signed copy of this contract must be
        received by Subbill.
        <br />
        <br />
        You may fax the signed agreement to 1-480-449-8823. You agree by
        clicking to accept these Terms & Conditions at the end of this contract
        that Subbill may withhold payments on new accounts until the signed
        contract is accepted and verified by Subbill. Subbill will issue payment
        to you on a weekly basis. The payment made will be for the net amount of
        the customer charges for the week ending on Saturday, one week prior to
        the week the payment is issued. While Subbill will make reasonable
        effort to make payment on Monday, if there are unusual circumstances
        such as delays in Subbill receiving payment from banks, holidays, or
        system failures, Subbill will make payment as soon after Monday as
        possible.
        <br />
        <br />
        The net amount of payment will be the amount of customer charges minus
        Subbill fees, security deposit, and refunds not processed in previous
        payments, chargebacks, ACH returned items, payment delivery fees, taxes,
        penalties, and any additional security deposit amounts. Subbill reserves
        the right to charge any of these deductions against the security deposit
        and to require you to replenish the security deposit to a level deemed
        appropriate solely by Subbill or its Merchant Bank.
        <br />
        <br />
        In order to resolve complaints and disputes, you agree that Subbill may
        issue refunds without your knowledge or approval in any situation
        Subbill thinks is appropriate. Subbill may also include the customer in
        its database of blocked accounts if there is an indication of fraud or
        inappropriate use of the customer's information by the customer or
        anyone else.
        <br />
        <br />
      </p>
      <h2 className="page__block-title">7. Cancellation</h2>
      You and Subbill can cancel this agreement at any time with 10 days written
      notice. Email and telephone are not acceptable forms of notice. Subbill
      also has the right to cancel this agreement without notice, if you violate
      the terms of this agreement, or Subbill's Acceptable Use Policies (AUP).
      Subbill's AUP is available on its website. Subbill will change its AUP
      from time to time and it is your responsibility to check it often to make
      sure you are in compliance. In cases of minor violations, Subbill will
      give you an opportunity to become compliant, but Subbill can cancel this
      agreement or take any other action Subbill feels is appropriate if you do
      not comply. Serious violations by you or any of your referrers or upon
      notification by any of the card associations or regulatory agencies to
      discontinue accepting transactions will result in Subbill canceling this
      agreement immediately.
      <br />
      <br />
      During the term of this Agreement or in the event that you cancel this
      Agreement, you agree that for a period of one year following such
      cancellation you will not attempt to circumvent Subbill by utilizing any
      of Subbill's acquirers or service providers for processing transactions
      for your websites.
      <br />
      <br />
      Subbill may terminate either (a) this Agreement, (b) any service or (c)
      both, immediately, if you are the subject of a bankruptcy order, become
      insolvent, make any arrangement or composition with or assignment for the
      benefit of creditors, go into voluntary or compulsory liquidation, have a
      receiver or administrator appointed over your assets, or if the equivalent
      of any such events under the laws of any of the relevant jurisdictions
      occurs to you.
      <br />
      <br />
      Upon cancellation/termination of your account, Subbill will hold all funds
      due to you for a minimum of six months as reserve for possible refunds,
      returned checks and chargebacks.
      <br />
      <br />
      <h2 className="page__block-title">8. Your Obligations</h2>
      You agree to use Subbill's services for the sale of legal content and
      services from your website. Without Subbill's prior written approval, you
      will not use Subbill's services for the sale of property or items that
      cannot be downloaded from your venue by your customer. You also agree to
      abide by the Terms and Conditions of this agreement and Subbill's
      Acceptable Use Policies (AUP). Subbill reserves the right to manually or
      electronically review your website(s) to ensure there are no violations of
      the Terms and Condition or the AUP.
      <br />
      <br />
      On Subbill's signup page for your venue, Subbill will plainly show the
      customer the price(s) you are charging and all terms and conditions of the
      purchase. If this agreement is terminated by either you or Subbill for any
      reason, you must leave the ability for customers to cancel their purchases
      through Subbill's services until there are no more active recurring
      memberships. If you do not do this, or if in Subbill's judgment there will
      be excessive chargebacks, or ACH returned items, Subbill will immediately
      issue refunds to your customers, and hold all payments and reserves until
      such time as Subbill is convinced that there will be no further refunds
      and/or chargebacks. The minimum time that Subbill will withhold payment is
      six months.
      <br />
      <br />
      You will always maintain the ability to respond to your customer's
      technical inquiries and try to resolve issues amicably. If, in Subbill's
      opinion customer complaints and/or chargebacks are excessive, Subbill may
      choose to cancel this agreement and you agree to immediately reimburse to
      Subbill all its expenses related to chargebacks and refunds.
      <br />
      <br />
      You may change the access prices and subscription options for your venue
      whenever you want. The correctness, functionality, and accuracy of these
      changes are your responsibility regardless of who makes them.
      <br />
      <br />
      Subbill's employees have entered into an agreement to protect Subbill's
      confidential and trade secret information even after their employment. You
      agree that it would be difficult for Subbill to enforce this agreement if
      you were to hire an employee or a former employee and it would be
      difficult for the employee to abide by the terms of the agreement. You
      therefore agree that you will not hire or enter into a contract of any
      kind with a current or former Subbill employee for a period of 24 months
      following the effective date of that employee's last date of employment
      with Subbill or any company affiliated with Subbill without Subbill's
      written prior approval.
      <br />
      <br />
      <h2 className="page__block-title">9. Authorization</h2>
      By signing this agreement you are stating that you are the owner and
      operator of the venue(s), are of legal age in the state or country where
      your business is located to enter in to this agreement, and have obtained
      all the necessary licenses and certificates required to perform your
      obligations. You will follow all laws and legal regulations that relate to
      your business regardless of where those laws and regulations originate,
      and will comply with any laws and regulations that may apply in the
      future.
      <br />
      <br />
      <h2 className="page__block-title">10. Privacy Policy</h2>
      You understand that Subbill's Privacy Policy. in part reads, “By using
      Subbill's services, you agree that we may release information obtained in
      the course of your use of Subbill's services when it is believed, in
      Subbill's sole and absolute discretion, that the release is appropriate or
      otherwise necessary to comply with the law, enforce our Agreement, and/or
      protect the contract and other rights of our Clients.
      <br />
      <br />
      <h2 className="page__block-title">11. Taxes</h2>
      Subbill and you both agree to pay any and all taxes that are imposed on
      Subbill or your respective business. If Subbill is required to pay taxes
      for you, you will immediately reimburse Subbill for those taxes and any
      fines, penalties and interest, or you agree that Subbill may deduct them
      from payments to you.
      <br />
      <br />
      <h2 className="page__block-title">12. Limitations of Liability</h2>
      Subbill WILL UTILIZE ITS BEST EFFORTS TO MAINTAIN ACCEPTABLE PERFORMANCE
      OF THE SERVICE, BUT Subbill MAKES ABSOLUTELY NO WARRANTIES WHATSOEVER,
      EXPRESSED OR IMPLIED, INCLUDING WARRANTY OF MERCHANTABILITY OR FITNESS FOR
      A PARTICULAR PURPOSE. Subbill cannot guarantee continuous service, service
      at any particular time, or integrity of data stored or transmitted via its
      system or via the Internet. Subbill will not be liable for the inadvertent
      disclosure of, or corruption or erasure of, data transmitted, received, or
      stored on its system. Subbill will not be liable to you or any of your
      customers for any claims or damages which may be suffered by you or your
      customers, including, but not limited to, losses or damages of any and
      every nature, resulting from the loss of data, unauthorized access to your
      website as a result of hackers or password trading, inability to access
      Internet, or inability to transmit or receive information, caused by, or
      resulting from, delays, non-delivery, or service interruptions whether or
      not caused by Subbill's fault or negligence. Subbill will not be
      responsible for your venue not being accessible on the Internet due to
      circumstances not under Subbill's direct control such as individual
      Internet user's own equipment capabilities, limitations, other Internet
      service provider limitations and/or browser software limitations.
      <br />
      <br />
      Subbill may discontinue any service, or may require fulfillment of
      conditions Subbill may choose to impose as a prerequisite for continuing
      any service upon thirty (30) days notice to you. However, Subbill may
      discontinue service immediately for fraud committed by you or your
      employees, illegal activity, or violations of its Acceptable Use Policies
      (AUP) and/or its other policies.
      <br />
      <br />
      Subbill's liability to you and any end user of the service or any other of
      its services is limited to the amount paid to and received by Subbill for
      service not accepted. In no event will Subbill be liable to you, or any
      end user or any other entity for any special, consequential, or other
      damages, however caused, whether for breach of contract, negligence or
      otherwise, even if Subbill has been advised of the possibility of such
      damage.
      <br />
      <br />
      You agree that Subbill is an Internet Service Provider ("ISP") under the
      Digital Millennium Copyright Act (DMCA) and understand that Subbill is
      required under the DMCA to have you remove any material that another party
      claims is infringing once Subbill has received, in its sole judgment, a
      proper notification. You also agree that if you do not comply with a
      request from Subbill to remove material under the DMCA or for any other
      reason, Subbill may at its discretion block access to new member signups
      and renewals and hold all funds until such time as Subbill is certain that
      the material is removed. Subbill may, at its sole discretion, determine
      who is an “infringer” and may terminate this agreement without notice to
      you if Subbill believes you to be a repeat infringer.
      <br />
      <br />
      <h2 className="page__block-title">13. Indemnification</h2>
      You are solely responsible for your venue, any associated website and all
      venue/website content, for your advertising and promotion, and that of
      anyone referring others to your venue. You are also responsible for
      insuring that you are the rightful owner or licensee for any copyrighted
      material, trademarks, or items that appear on your venue.
      <br />
      <br />
      You will take all necessary measures to preclude Subbill from being made a
      party to any lawsuit or claim regarding the service provided to or by you,
      or any end user. You hereby agree to indemnify Subbill and hold Subbill
      harmless from any and all claims of whatever nature brought by any anyone
      against Subbill in excess of the remedy set forth herein.
      <br />
      <br />
      You hereby agree to reimburse Subbill for any and all expenses associated
      with Subbill being involved with any civil, criminal, regulatory or
      investigatory actions brought by you or against you by anyone, including
      expenses associated with providing information that Subbill, in its sole
      discretion, believes it legally must provide or has been authorized by you
      to provide. You also understand and agree that Subbill will deduct those
      expenses from monies due to you and you agree to reimburse Subbill for any
      expense that exceeds what Subbill is able to deduct from your account.
      <br />
      <br />
      <h2 className="page__block-title">14. Relationship of Parties</h2> Nothing
      in this Agreement will create any partnership, joint venture, agency,
      franchise, sales representative, or employment relationship between the
      parties. You will have no authority to make or accept any offers or
      representations on our behalf. You will not make any statement, whether on
      your site or otherwise, that reasonably would contradict anything in this
      Section. Neither party is the agent of the other and the COMPANY expressly
      disclaims responsibility for any conduct by you in violation of our terms
      of agreement.
      <br />
      <br />
      You hereby agree to reimburse Subbill for any and all expenses associated
      with Subbill being involved with any civil, criminal, regulatory or
      investigatory actions brought by you or against you by anyone, including
      expenses associated with providing information that Subbill, in its sole
      discretion, believes it legally must provide or has been authorized by you
      to provide. You also understand and agree that Subbill will deduct those
      expenses from monies due to you and you agree to reimburse Subbill for any
      expense that exceeds what Subbill is able to deduct from your account.
      <br />
      <br />
      <h2 className="page__block-title">15. Term</h2> This agreement will
      continue until terminated by either party under the provisions above.
      Subbill may terminate this agreement immediately and without notice if you
      violate the terms of this agreement, its Acceptable Use Policies (AUP),
      its policies, or the law. Subbill may, at its sole discretion, suspend or
      cancel services for your account if it stays inactive for any consecutive
      ninety (90) day periods. Reinstatement of your account, after suspension
      or cancellation, will require Subbill approval.
      <br />
      <br />
      <h2 className="page__block-title">16. Survival</h2> The rights and
      obligations of the parties in this Agreement that would by their nature or
      context be intended to survive the expiration or termination of this
      Agreement shall so survive, including without limitation the provisions of
      this Agreement in sections 5, 6, 9, 10, 11, 12, 16, 17, 18, and 19.
      <br />
      <br />
      <h2 className="page__block-title">17. Confidentiality</h2> Unless required
      by law, you will treat Subbill's intellectual property as confidential and
      proprietary, and not disclose them to anyone without Subbill's prior
      written consent. You may not use Subbill's trademarks and advertising
      without Subbill's permission, and then only in ways agreed to by you and
      Subbill. Upon termination of this agreement, you will promptly return any
      of Subbill's property in your possession.
      <br />
      <br />
      <h2 className="page__block-title">
        18. Invalid or Unenforceable Provisions
      </h2>{" "}
      If any provision of this Agreement is held to be invalid by a court of
      competent jurisdiction, then the remaining provisions will nevertheless
      remain in full force and effect. You and Subbill agree to renegotiate in
      good faith any term held invalid and to be bound by any mutually agreed to
      substitute provision.
      <br />
      <br />
      <h2 className="page__block-title">19. Choice of Law: Venue</h2> This
      Agreement is governed by and construed under the laws of the State of
      Arizona and the United States of America. The federal and state courts of
      the State of Arizona will have exclusive jurisdiction to adjudicate any
      non-arbitrable dispute arising out of this Agreement.
      <br />
      <br />
      <h2 className="page__block-title">20. Disputes</h2> Subbill will attempt
      to resolve all disputes arising out of this Agreement in a spirit of
      cooperation without formal proceedings. Any dispute which cannot be so
      resolved (other than the collection of money due on unpaid invoices and
      other than injunctive relief) will be subject to arbitration upon written
      demand of either party. Except for disputes resulting from unpaid Subbill
      invoices, the parties agree to arbitrate this matter prior to any state,
      federal, or international court action. The arbitration will take place in
      Phoenix, Arizona before a single arbitrator under the auspices of the
      American Arbitration Association‘s (“AAA”) Commercial Arbitration Rules
      currently in effect at that time. Arizona law will be applied in the
      arbitration and any other subsequent legal hearings.
      <br />
      <br />
      The arbitrator will not have the authority to award punitive damages or
      any other form of relief not contemplated in the contract. The arbitrator
      will render a written opinion setting forth the basis on which he or she
      arrived at the decision regarding each issue submitted to arbitration. The
      decision of each issue submitted to arbitration will be final and binding
      only to the extent it is accompanied by a written explanation of the basis
      upon which it was arrived at. Judgment upon the award, if any, rendered by
      the arbitrator may be entered in any court having jurisdiction thereof.
      <br />
      <br />
      Should any legal action permissible under this Agreement be instituted to
      enforce the terms and conditions of this Agreement, in particular the
      right to collect money due on unpaid invoices, the prevailing party will
      be entitled to recover reasonable attorneys' fees and expenses incurred at
      both the trial and appellate levels. Parties may modify the dispute clause
      only through a stipulation signed by both parties.
      <br />
      <br />
      <h2 className="page__block-title">
        21. Entire Agreement; Modifications
      </h2>{" "}
      This Agreement sets forth the entire Agreement and understanding between
      the parties and merges all prior discussion between them. Subbill reserves
      the right to modify this agreement at any time. Utilization of the service
      by you and/or your customers following the effective date of any change or
      modification of this Agreement on Subbill's website will constitute
      acceptance by you of such change(s). Otherwise, this Agreement may not be
      modified except by the written consent of both parties.
      <br />
      <br />
      For your own protection, you understand and agree that Subbill will only
      accept requested changes and modifications to this agreement that are in
      writing and signed by the individual(s) signing this agreement.
      <br />
      <br />
      <h2 className="page__block-title">
        22. Change of Ownership and Multiple Signatories
      </h2>{" "}
      In the event of changes in ownership of your company or, venue(s) covered
      by this agreement, you and you only must notify Subbill in writing of the
      change. Subbill will then require the new owner to execute a new agreement
      with Subbill. In the event you want to change the information in this
      agreement regarding the payments to you, you and only you must notify
      Subbill in writing.
      <br />
      <br />
      Subbill strongly discourages clients from having more than one person sign
      this agreement. If you choose to have more than one person sign this
      agreement, please be advised that, when notified of a dispute, Subbill
      will not make any changes to the account or disburse or refund any monies
      until Subbill receives either written stipulation signed by all
      signatories or a court order.
      <br />
      <br />
      <h2 className="page__block-title">Exhibit A: Tangible Goods AUP</h2> The
      following modifies or reinforces Subbill's standard AUP for the purpose of
      sale of tangible goods.
      <br />
      <br />
      1. VIOLATIONS OF INTELLECTUAL PROPERTY We will not process for websites
      that infringe, or contain products that infringe on the intellectual
      property rights of other parties. 2. MAILING LISTS AND PERSONAL
      INFORMATION We do not allow the sale of email lists, opt-in lists, leads,
      mailing lists, social networking or IM info, telephone numbers, or FAX
      numbers. 3. PROHIBITED ITEMS; NOT FOR SALE
      <br />
      <br />
      <h2 className="page__block-title">GENERAL</h2> The sale of any of the
      below is not allowed: Cars, Boats, Airplanes or other Motorized
      Transportation Real estate
      <br />
      <br />
      <h2 className="page__block-title">
        FIREARMS, WEAPONS, EXPLOSIVES AND HAZARDOUS MATERIALS
      </h2>
      The sale of any of the below is prohibited: Fire arms Ammunition Air Guns
      Firearm parts or accessories Pyrotechnic devices and hazardous materials
      Fireworks Hazardous Chemicals Radioactive materials Combustible or
      Corrosive materials Pesticides
      <br />
      <br />
      <h2 className="page__block-title">
        DRUGS, DRUG PARAPHERNALIA, AND RELATED ITEMS
      </h2>{" "}
      The sale of any of the below is prohibited: Alcohol Tobacco Illegal Drugs
      Drug Paraphernalia Narcotics Steroids Any and all items that are primarily
      intended or designed for use in manufacturing, concealing or using a
      controlled substance Anything described in a manner that suggests
      ingesting, inhaling, extracting or otherwise using any substance or
      compound from the legal substance will provide the same effect as an
      illegal drug, compound or substance or that will provide unsubstantiated
      health benefits
      <br />
      <br />
      <h2 className="page__block-title">MEDICAL</h2> The sale of any of the
      below is prohibited: Prescription Drugs Controlled substances Prescription
      devices "Miracle Cures" or any health/nutritional products offering
      guaranteed results Computer, Internet, and Marketing
      <br />
      <br />
      <h2 className="page__block-title">
        Computer and Device Applications
      </h2>{" "}
      The sale of any of the below is prohibited: Software designed to harvest
      e-mail addresses or other personal information from the Internet Software
      designed to post messages on multiple forums, message boards, bulletin
      boards, newsgroups, etc. Software designed to facilitate the transmission
      of Unsolicited Bulk Email ("UBE") Malware
      <br />
      <br />
      <h2 className="page__block-title">CLOTHING AND ACCESSORIES (USED)</h2> The
      sale of any of the below is prohibited: All used clothing items must
      include a statement that the item has been properly cleaned Used under
      garments are prohibited
      <br />
      <br />
      <h2 className="page__block-title">Offensive Material</h2> Prohibited are
      Goods, literature, products, or other materials that: Promote hatred,
      racism or religious persecution Defame or slander any person or groups of
      people based on race, ethnicity, national origin, religion, sex, or other
      factors Defame or slander any person or groups of people protected from
      defamation or slander by applicable law (such as the protection afforded
      to the royal family in some jurisdictions) Encourage or incite violent
      acts Promote the facilitation of suicide or euthanasia Promote intolerance
      or hatred Promote or support membership in terrorist groups or other
      organizations prohibited by law Contravene public morality Are considered
      obscene
      <br />
      <br />
      <h2 className="page__block-title">SECURITY CIRCUMVENTION</h2> Prohibited
      are products such as lock picks that are designed to circumvent the below
      methods or are designed to gain access to restricted features, systems or
      services they have not paid for: Locks Programming codes or security
      features Police and other government identification and equipment
      <br />
      <br />
      <h2 className="page__block-title">
        4. ILLEGAL PRODUCTS, REGULATED GOODS AND MISC. ITEMS THAT WILL NOT BE
        ACCEPTED
      </h2>
      Airbags Batteries containing mercury Car titles License plates Postage
      meters Recalled Products Government IDs and licenses, or items that claim
      to be, or that look similar to, government identification documents Stolen
      property Postage meters Illegal Telecommunications services Radar and
      laser jammers Cuban Cigars Cable Descramblers Items that encourage,
      promote, facilitate or instruct others to engage in illegal activities
      Electronic Surveillance Equipment or products designed primarily to record
      the private oral, wire or electronic conversations of others without their
      knowledge or permission Live Animals Humans, the human body, or any human
      body parts or fluids Cultural Artifacts, Antiquities, Native American
      Artifacts Food - Perishable, non-prepackaged foods Precious metals or
      stones Any other items or activities which in Company's judgment are
      illegal, inappropriate, or offensive
      <br />
      <br />
      <h2 className="page__block-title">
        Exhibit B: Subbill Tangible Goods Policies
      </h2>
      A. All consumer complaints that require the Company's intervention will
      always be resolved in favor of the consumer. B. The client is required to
      ship all orders within 3 business days (excluding weekends and major U.S.
      holidays). The orders must be shipped by 6pm (local time) on the third
      day. C. If a product is not shipped within the 3 business day period, the
      Client must refund the transaction before the end of said 3 day period. D.
      Client may not accept orders for any products that are unavailable for
      shipment within the 3 business day period and must remove the consumer's
      ability to purchase the product. E. Client may ship all materials via
      public and/or private shipping companies such as USPS, FedEx, DHL or UPS
      as long as they provide a verifiable tracking ID. F. Clients are required
      to prominently list on any website selling tangible goods a clear and
      reasonable refund and exchange policy that is appropriate for managing
      customer issues and does not result in excessive complaints, as determined
      by the Company. Clients are required to prominently list on any website
      selling tangible goods a clear and reasonable return and exchange policy
      that is appropriate for managing customer issues and complaints. The
      return and exchange policy must include a time frame for how long the
      customer has to make a return, who is responsible for paying the shipping
      costs (seller or customer) and the instructions for completing a return.
      G. Company does not allow exchanges to be processed through its systems.
      All exchanges are handled directly between Client and Consumer. Even in
      the event of an exchange, Company reserves the right without client
      approval to refund the consumer in the event consumer complains to
      Company. H. All tangible goods orders must be processed through a separate
      Company sub-account than intangible purchasing. I. It is the client's
      responsibility to handle all customer product and shipping inquiries.
      Client is required to prominently display their company name, address,
      phone number, and email address on their website to offer support with a
      response time of 24 hours. J. The client is required to inform Company and
      all consumers when an order is shipped and provide both with the shipping
      company name, shipping address, tracking number, and the transaction ID
      number. K. If there are any delays or changes to the order or product
      availability, Client must immediately issue a refund and notify both the
      consumer and the Company. L. All products must have a product description
      that clearly and accurately depicts the product. M. Any used products
      being sold by the client must clearly state the condition of the product.
      Any used products must be properly cleaned before shipment and a notice
      must be provided before purchasing indicating the products are used and
      have been properly cleaned. N. The client indemnifies Subbill, its owners,
      employees, and agents against any costs or legal fees resulting from
      client's actions including claims of illegal activity by any legal
      jurisdiction including but not limited to Federal, State, Local, or
      International. O. All payments are subject to be delayed or held at the
      sole discretion of Subbill.
    </Page>
  );
}

export default TermsOfRervice;
