import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import Logo from "./assets/logo.svg";
import "./Footer.scss";

function Footer() {
  const isMerchant = useSelector((state) => state.mode.isMerchant);
  const { t } = useTranslation();
  const location = useLocation();

  if (location.pathname === "/login") return null;

  return (
    <div className="footer">
      <div className="footer__inner">
        <div className="footer__body">
          <Link to="/">
            <img className="footer__logo" src={Logo} alt="Logo" />
          </Link>
          <div className="footer__info">
            <ul className="footer__info-links">
              <li className="footer__info-links-item">
                <Link to="about">{t("footer.link_1")}</Link>
              </li>
              <li className="footer__info-links-item">
                <Link to="/terms">{t("footer.link_2")}</Link>
              </li>
              <li className="footer__info-links-item">
                <Link to="/privacy">{t("footer.link_3")}</Link>
              </li>
              {isMerchant && (
                <>
                  <li className="footer__info-links-item">
                    <Link to="/accept">AUP</Link>
                  </li>
                  <li className="footer__info-links-item">
                    <Link to="/copyright">DMCA</Link>
                  </li>
                </>
              )}
            </ul>
            <Link className="footer__info-copy" to="/copyright">
              ©2012 - 2021 {t("footer.copy")}
            </Link>
          </div>
        </div>
        <div className="footer__bottom">{t("footer.bottom")}</div>
      </div>
    </div>
  );
}

export default Footer;
