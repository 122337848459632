import { combineReducers } from "redux";
import { mode, lang, modal } from "./reducers";

const rootReducer = combineReducers({
  modal,
  mode,
  lang,
});

export default rootReducer;
