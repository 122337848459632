import React from "react";
import { Link } from "react-router-dom";
import { Page } from "../../components/Page";

import Magnifier from "./assets/magnifier.png";
import Stop from "./assets/stop.png";
import Qustion from "./assets/question.png";
import { useTranslation } from "react-i18next";
import LoginModal from "./LoginModal/LoginModal";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/actions/modal";

function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openLoginModal = () => dispatch(showModal("login"));

  return (
    <Page className="home-consumer">
      <div className="row">
        <div className="col-md-4 offset-md-0 col-sm-10 offset-sm-1">
          <div className="home-consumer__card">
            <img src={Magnifier} alt="" />
            <p className="home-consumer__card-text">
              {t("home.consumer.card_1.text")}
            </p>
            <button
              className="home-consumer__card-link"
              style={{
                padding: "20px 15px",
              }}
              onClick={openLoginModal}
            >
              {t("home.consumer.card_1.button")}
            </button>
          </div>
        </div>
        <div className="col-md-4 offset-md-0 col-sm-10 offset-sm-1">
          <div className="home-consumer__card">
            <img src={Stop} alt="" />
            <p className="home-consumer__card-text">
              {t("home.consumer.card_2.text")}
            </p>
            <button
              className="home-consumer__card-link"
              style={{ padding: "20px 15px" }}
              onClick={openLoginModal}
            >
              {t("home.consumer.card_2.button")}
            </button>
          </div>
        </div>
        <div className="col-md-4 offset-md-0 col-sm-10 offset-sm-1">
          <div className="home-consumer__card">
            <img src={Qustion} alt="" />
            <p className="home-consumer__card-text">
              {t("home.consumer.card_3.text")}
            </p>
            <Link className="home-consumer__card-link" to="/questions">
              {t("home.consumer.card_3.button")}
            </Link>
          </div>
        </div>
      </div>
      <LoginModal />
    </Page>
  );
}

export default Home;
