import React from "react";

import "./Services.scss";
import { Page } from "../../components/Page";
import Man from "./assets/man.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Services() {
  const { t } = useTranslation();

  return (
    <Page title={t("services.title")} className="services">
      <div className="card">
        <img className="card__img" src={Man} alt="" />
        <h2 className="card__title">{t("card_1.title")}</h2>
        <p className="card__text">{t("card_1.text")}</p>
        <Link className="card__button" to="/app-form">
          {t("card_1.button")}
        </Link>
      </div>
      {Array(6)
        .fill(0)
        .map((item, index) => (
          <React.Fragment key={index}>
            <h2 className="page__block-title">
              {t(`services.text_${index + 1}.title`)}
            </h2>
            <p className="page__block-text">
              {t(`services.text_${index + 1}.text`)}
            </p>
          </React.Fragment>
        ))}
    </Page>
  );
}

export default Services;
