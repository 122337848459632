import { types } from '../types/modal';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  show: [],
}

export default function modal(state = initialState, action = {}) {
  switch (action.type) {
    case types.MODAL.SET:
      return {
        ...state,
        show: [action.id]
      }
    case types.MODAL.SHOW:
      return {
        ...state,
        show: [...state.show, action.id]
      }
    case types.MODAL.HIDE:
      return {
        ...state,
        show: state.show.filter((item) => item !== action.id)
      }
    case types.MODAL.TOGGLE:
      return {
        ...state,
        show: state.show.indexOf(action.id) > -1
        ? state.show.filter((item) => item !== action.id )
        : [...state.show, action.id]
      }
    case LOCATION_CHANGE:
      return initialState
    default:
      return state
  }
}