import { types } from "../types/modal";


const setModal = (id) => ({
    type: types.MODAL.SET,
    id,
})

const showModal = (id) => ({
    type: types.MODAL.SHOW,
    id,
})

const hideModal = (id) => ({ 
    type: types.MODAL.HIDE,
    id,
})

const toggleModal = (id) => ({
    type: types.MODAL.TOGGLE,
    id,
})

export {
    setModal,
    showModal,
    hideModal,
    toggleModal,
}