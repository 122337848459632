import { modeTypes } from "../types/mode";

const isMerchant = () => {
  if (localStorage.getItem("mode") === null) return false;

  return JSON.parse(localStorage.getItem("mode"));
};

const initialState = {
  isMerchant: isMerchant(),
};

const modeReducer = (state = initialState, action) => {
  switch (action.type) {
    case modeTypes.toggleMode:
      localStorage.setItem("mode", !state.isMerchant);

      return {
        ...state,
        isMerchant: !state.isMerchant,
      };
    default:
      return state;
  }
};

export default modeReducer;
