import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import "./Login.scss";

function Login() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="login">
      <div className="login__back" onClick={() => history.push("/")}>
        <span></span>
      </div>
      <form className="login__form">
        <div className="login__form-control">
          <label htmlFor="login">{t("login.label_1")}:</label>
          <input className="login__form-input" type="text" name="login" />
        </div>
        <div className="login__form-control">
          <label htmlFor="login">{t("login.label_2")}:</label>
          <input
            className="login__form-input"
            type="password"
            name="password"
          />
        </div>
        <a className="login__form-res-password" href="/">
          {t("login.res_pass")}
        </a>
        <button className="login__form-submit" type="submit">
          {t("login.submit")}
        </button>
      </form>
    </div>
  );
}

export default Login;
