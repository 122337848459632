import React, { useState } from "react";
import cn from "classnames";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { toggleMode } from "../../redux/actions/mode";
import { setRu, setEng } from "../../redux/actions/lang";

import { menu } from "./_menu";
import "./Header.scss";
import logo from "./assets/logo.svg";
import RussiaIcon from "./assets/russia.svg";
import UKIcon from "./assets/united-kingdom.svg";

function Header() {
  const [isActive, setIsActive] = useState(false);
  const isMerchantMode = useSelector((state) => state.mode.isMerchant);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const menuLinks = isMerchantMode ? menu.merchant : menu.cunsumer;

  const setRuLang = () => i18n.changeLanguage("ru");
  const setEngLang = () => i18n.changeLanguage("en");

  const onToggleMode = () => {
    dispatch(toggleMode());
    history.push("/");
  };

  if (location.pathname === "/login") return null;

  return (
    <div className={cn("header", { "header--consumer": !isMerchantMode })}>
      <div className="header__top">
        {isMerchantMode && (
          <div className="header__lang">
            <div className="header__lang-item" onClick={setRuLang}>
              <img src={RussiaIcon} alt="" />
            </div>
            <div className="header__lang-item" onClick={setEngLang}>
              <img src={UKIcon} alt="" />
            </div>
          </div>
        )}
        <div className="header__mode">
          <span>{t("header.consumer")}</span>
          <label className="header__mode-toggle">
            <input
              type="checkbox"
              checked={isMerchantMode}
              onChange={onToggleMode}
            />
            <span />
          </label>
          <span>{t("header.merchant")}</span>
        </div>
      </div>
      <div className="header__body">
        <Link className="header__logo" to="/">
          <img src={logo} alt="Logo" />
        </Link>
        <div className="header__nav">
          <div
            className={cn("header__nav-inner", {
              "header__nav-inner--active": isActive,
            })}
          >
            <div className="menu-close" onClick={() => setIsActive(false)} />

            {menuLinks.map((item, index) => (
              <li className="header__nav-item">
                <NavLink key={index} to={item.href} exact={item.exact}>
                  {t(item.title)}
                </NavLink>
              </li>
            ))}

            {!isMerchantMode && (
              <div className="header__lang">
                <div className="header__lang-item" onClick={setRuLang}>
                  <img src={RussiaIcon} alt="" />
                </div>
                <div className="header__lang-item" onClick={setEngLang}>
                  <img src={UKIcon} alt="" />
                </div>
              </div>
            )}
          </div>
          <div
            className="header__nav-burger"
            onClick={() => setIsActive((prev) => !prev)}
          >
            <span />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
