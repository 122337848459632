import React from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Page } from "../../components/Page";
import "./ApplicationForm.scss";

function ApplicationForm() {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Page title={t("app_form.title")}>
      <p className="page__block-text">{t("app_form.text")}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-xl-9">
            <div className="row">
              <div className="col-md-6 control">
                <label htmlFor="name">{t("field.comp_name")}:</label>
                <input ref={register({})} name="name" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="email">{t("field.email")}:</label>
                <input ref={register({})} name="email" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="company">{t("field.comp_name")}:</label>
                <input ref={register({})} name="company" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="url">{t("field.site_url")}:</label>
                <input ref={register({})} name="url" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="country">{t("field.country")}:</label>
                <input ref={register({})} name="country" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="phone">{t("field.phone")}:</label>
                <input ref={register({})} name="phone" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="volume">{t("field.volume")}:</label>
                <input ref={register({})} name="volume" type="text" />
              </div>
              <div className="col-md-6 control">
                <label htmlFor="buisnes">{t("field.category")}:</label>
                <select ref={register({})} name="buisnes" type="text">
                  <option value={t("app_form.select_1")}>
                    {t("app_form.select_1")}
                  </option>
                  <option value={t("app_form.select_2")}>
                    {t("app_form.select_2")}
                  </option>
                  <option value={t("app_form.select_3")}>
                    {t("app_form.select_3")}
                  </option>
                  <option value={t("app_form.select_4")}>
                    {t("app_form.select_4")}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-xl-3 checkboxes">
            <label>{t("field.payment")}:</label>
            <div className="checkboxes__inner">
              <label htmlFor="cards">
                <input ref={register({})} name="cards" type="checkbox" />
                {t("field.cards")}
              </label>
              <label htmlFor="transfers_1">
                <input ref={register({})} name="transfers_1" type="checkbox" />
                {t("field.bank_transfers_1")}
              </label>
              <label htmlFor="transfers_2">
                <input ref={register({})} name="transfers_2" type="checkbox" />
                {t("field.bank_transfers_2")}
              </label>
              <label htmlFor="transfers_3">
                <input ref={register({})} name="transfers_3" type="checkbox" />
                {t("field.bank_transfers_3")}
              </label>
              <label htmlFor="wallets">
                <input ref={register({})} name="wallets" type="checkbox" />
                {t("field.wallets")}
              </label>
              <label htmlFor="payments">
                <input ref={register({})} name="payments" type="checkbox" />
                {t("field.prepaid")}
              </label>
              <label htmlFor="phone">
                <input ref={register({})} name="phone" type="checkbox" />
                {t("field.mobile")}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12 control">
            <label htmlFor="message">{t("field.comments")}:</label>
            <textarea name="message" />
          </div>
          <div className="col-md-3">
            <button type="submit">{t("field.submit")}</button>
          </div>
        </div>
      </form>
    </Page>
  );
}

export default ApplicationForm;
