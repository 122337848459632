import React from "react";
import { Page } from "../../components/Page";

function AcceptPolicy() {
  return (
    <Page title="Merchant Acceptable Use Policy">
      All merchants of Subbill (Cyprus) Limited (“Subbill”) agree to comply with
      the following policies. Subbill may change and update these policies as it
      considers necessary.
      <br />
      <br />
      <h2 className="page__block-title">Introduction</h2>
      Subbill's primary purpose is to facilitate and manage access to merchants'
      venues for consumers. Subbill intends to accept payment from consumers for
      access to merchants' sites or services with minimal or no interference
      from Subbill. But many of the individual credit card associations have
      specific guidelines for use. In addition, many Internet Service Providers
      also have specific guidelines pertaining to general use of the Internet.
      As the Internet expands, it is increasingly common for an Internet Service
      Provider, such as Subbill, to be blocked from use of another
      organization's systems due to violations of that system's acceptable use
      policy. Because Subbill runs multi-user systems, merchant actions can have
      a severe impact on other merchants' ability to use the systems. This is
      unfair to all Subbill users. Accordingly, Subbill has developed this
      Acceptable Use Policy (the “AUP”). This AUP is intended to inform the
      merchant of what Subbill considers to be acceptable conduct in relation to
      the Internet, and of what actions Subbill may take, with or without
      notice, in the event that Subbill becomes aware of inappropriate use of
      its service. This AUP will be used to help Subbill's system administrators
      deal with complaints from users of Subbill or other Internet-connected
      systems, and to determine when action should be taken. It is expected that
      the merchant will follow the policies set out below. These policies are
      drawn from applicable law and generally accepted standards of Internet
      conduct, and are intended to ensure protection of Subbill's technical
      resources, ability to continue to provide high quality service to the
      merchant, and protect Subbill's reputation as a service provider.
      <br />
      <br />
      <h2 className="page__block-title">
        Access to Subbill's service is provided subject to the following:
      </h2>
      <br />
      <br />
      <h2 className="page__block-title">Security</h2>
      The merchant is responsible for all use of the merchant's account and
      confidentiality of passwords, including choosing safe passwords and
      ensuring file protections are set correctly. Subbill will promptly suspend
      or change access to your Subbill merchant account on notice by you that
      your password has been lost, stolen, or otherwise compromised. Subbill is
      not liable for any usage and or charges before Subbill making the
      necessary account alteration. Electronic mail on this system is as private
      as Subbill can make it. The merchant is reminded that no computer network
      can ever be considered completely safe from intrusion. Email may pass
      through many computer systems and should not be considered a secure means
      of communication unless encrypted—and even encrypted information is only
      as secure as the encryption method used.
      <br />
      <br />
      <h2 className="page__block-title">Non-Transferability of Account</h2>
      The right to use Subbill's Internet service is not transferable. Use of
      Subbill's accounts is expressly limited to the individual or business
      whose name appears on the merchant services agreement.
      <br />
      <br />
      <h2 className="page__block-title">Unacceptable Conduct</h2>
      The following types of conduct are grounds for immediate suspension of
      service pending investigation by Subbill and may result in termination of
      all accounts held by the individual, corporation, or website associated
      with these violations.
      <br />
      <br />
      You will also be held responsible for the actions of your business
      relationships (referrers, associates, etc.) that impact Subbill. Your
      cooperation is necessary to make sure that those involved in these
      relationships comply with this AUP and those of Subbill's providers. If
      they violate any of the following policies, Subbill expects you to make
      certain that they promptly stop the non-compliant action or that you
      discontinue your association with them. Continuing violations by your
      business relationships may result in Subbill at its discretion having to
      terminate payment to your business relationships or Subbill's agreement
      with you.
      <br />
      <br />
      <h2 className="page__block-title">1. Spamming or Harassment</h2>
      <ol>
        <li>
          Posting a single article or substantially similar articles to an
          excessive number of newsgroups (i.e., more than 20) or continued
          posting of articles which are off-topic according to the newsgroup
          charter, or which provoke complaints from the regular readers of the
          newsgroup for being inappropriate.
        </li>
        <br />
        <li>
          Sending unsolicited mass emails (i.e., to more than 25 users) that
          provoke complaints from the recipients.
        </li>
        <br />
        <li>
          Engaging in either a. or b. from a provider other than Subbill to draw
          attention to a website housed within Subbill's networks or covered by
          Subbill's agreement with the merchant.
        </li>
        <br />
        <li>
          Engaging in abuse or harassment of other individuals on the Internet
          after being asked to stop by those individuals or by Subbill.
        </li>
        <br />
        <li>
          Email bombing, i.e., sending large volumes of unsolicited email to
          individuals or to individual business accounts.
        </li>
        <br />
        <li>
          Impersonating another user or otherwise falsifying one's username in
          email, Usenet postings, on Internet Relay Chat (IRC), or with any
          other Internet service. (This does not preclude the use of nicknames
          in IRC or the use of anonymous retailer services.)
        </li>
      </ol>
      <br />
      <br />
      <h2 className="page__block-title">
        2. Network Unfriendly or Illegal Activity
      </h2>
      <br />
      <ol>
        <li>
          Attempts, whether successful or not, to gain access to any other
          system or users' private data without express consent of the user.
        </li>
        <br />
        <li>
          Attempts to interfere with the regular workings of Subbill's systems
          or network connections or which adversely affect the ability of other
          people or systems to use Subbill's services or the Internet.
        </li>
        <br />
        <li>
          Any unauthorized attempts by a user to gain access to any account not
          belonging to that user on this or any other of Subbill's systems.
        </li>
        <br />
        <li>
          Any activity, which violates any local, state, provincial, federal, or
          international law or regulation.
        </li>
        <br />
        <li>
          Repeated submissions of transactions to Subbill using the same or
          similar IPs with varying identification information.
        </li>
      </ol>
      <br />
      <br />
      <h2 className="page__block-title">3. Violation of Subbill Policy</h2>
      <ol>
        <br />
        <li>
          Any attempt to bypass or remove Subbill's name, logo, or customer
          support link from the signup page.
        </li>
        <br />
        <li>
          Failure to fulfill access or services sold to customer in either a
          trial transaction, regular transaction, or recurring transaction.
        </li>
        <br />
        <li>
          The posting, display, or advertising of any image using a model or
          models under 18-years old anywhere on the site whether the models are
          clothed or unclothed.
        </li>
        <br />
        <li>
          Marketing the site using content including “Kids,” “Lolita,” “Pedo,”
          “Peta,” “Peto,” “Pre-teen,” “Pedophile,” “Underage,” “Child,” or any
          other words, images, or descriptions that would lead someone to
          believe that the models are less than 18-years old is not permitted
          anywhere on venue, including the URL and metatags.
        </li>
        <br />
        <li>
          The posting or display of any image or wording depicting or related to
          extreme violence, incest, snuff, scat or the elimination of any bodily
          waste on another person, mutilation, or rape anywhere on the site,
          including the URL and metatags.
        </li>
        <br />
        <li>
          The posting or display of any image or wording depicting or related to
          bestiality anywhere on the site, including the URL and metatags.
        </li>
        <br />
        <li>
          The posting or display of any image or wording related to any website
          running, participating, or advertising acts allowing the subscriber to
          bet or gamble on an uncertain outcome, or to play a game of chance for
          stakes.
        </li>
        <br />
        <li>
          Any attempt to display, sell, or transfer materials that violate or
          infringe any copyright, trademark, right of publicity, patent,
          statutory, common law, or proprietary rights of others, or contain any
          thing obscene, libelous, or threatening.
        </li>
      </ol>
      <br />
      <br />
      <strong>Note:</strong> Previously accepted “Celebrity sites” must either
      follow an acceptable “news” format, such as “movie review,” e-magazine, or
      tabloid formats, or you must have and produce on Subbill's request written
      documentation of your right to use the material on your website. That
      documentation must be: (1) a license of the rights; (2) consent from the
      rights holder or their agent; or (3) a written statement from your
      attorney explaining your claim to have a lawful right, or a legal defense,
      to display the material. Subbill may at its sole discretion accept or
      reject your site.
      <br />
      <br />
      <ol>
        <li>
          Display of the trade or service marks of any credit card company on
          any webpage is specifically prohibited without the express written
          permission of the credit card company.
        </li>
        <br />
        <li>
          Reproduction or transmission of any material in violation of any
          local, state, provincial, federal, or international law or regulation
          is prohibited. Subbill makes every attempt to work with both U.S. and
          foreign law enforcement agencies to provide information about the
          providers and purchasers of the material. This includes the posting or
          display of any image or wording instructing users how to make or
          perform devices or situations that may violate any state, provincial,
          federal, or international law.
        </li>
        <br />
        <li>
          Websites must comply with 18 U.S.C. §§ 2257–2257A. Merchants must
          determine their legal responsibility to and method of complying with
          18 U.S.C. §§ 2257–2257A where required to do so.
        </li>
        <br />
        <li>
          Any material uploaded to a Subbill subscription page (aka “join page”)
          must be non-sexual in nature, and may only include non-nude
          individuals. Subbill may review and reject any material for any
          reason.
        </li>
        <br />
        <li>
          Any attempt to mislead the consumer about the site's content or actual
          initial or recurring pricing of the venue.
        </li>
        <br />
        <li>
          Any attempt to mislead the consumer about the product or service
          provided or the price for that product or service.
        </li>
        <br />
        <li>
          Failure to place a clear disclosure of trial periods and recurring
          charges conspicuously on the website.
        </li>
        <br />
        <li>
          Using any wording relating to credit card use for age verification
          purposes.
        </li>
        <br />
        <li>
          Use of blind links to the payment page. Consumers should have a
          reasonable expectation of opening a payment form when they click the
          link.
        </li>
        <br />
        <li>
          No part of the website may be hosted on a free web host or anywhere
          that violates the host's acceptable use policy.
        </li>
        <br />
        <li>
          The members' area of the venue must not be inaccessible to users for
          more than a 24-hour period at a time, and not more than one 24-hour
          period in a 30-day period.
        </li>
        <br />
        <li>
          If the merchant chooses to use the rebilling option for sites
          containing members' areas, the members' areas must be updated in a
          time frame that is equal to or less than the rebilling cycle. For
          example, if the merchant bills every 30 days, the merchant must update
          its members' area at least every 30 days.
        </li>
        <br />
        <li>
          Subbill will not process transactions for venues offering shell
          accounts. Subbill may cancel any accounts whose primary use can be
          determined as supporting the use of bots or any other programs
          executed on a server through a Telnet or a dial-up account.
        </li>
        <br />
        <li>
          Subbill will not process for sites advertising or selling the
          following:
        </li>
      </ol>
      <ul>
        <br />
        <li>Buyers' clubs or membership clubs;</li>
        <br />
        <li>Credit counseling or credit repair services;</li>
        <br />
        <li>Direct marketing or non-Internet type subscription merchants;</li>
        <br />
        <li>Infomercial merchants;</li>
        <br />
        <li>Multi-level marketing businesses;</li>
        <br />
        <li>Outbound telemarketers;</li>
        <br />
        <li>Prepaid phone cards or prepaid phone services;</li>
        <br />
        <li>Rebate-based businesses;</li>
        <br />
        <li>“Up-Sale” merchants;</li>
        <br />
        <li>Free grants or grant giveaways;</li>
        <br />
        <li>
          Cash, money-making opportunities, or making money at home
          opportunities;
        </li>
        <br />
        <li>“Cash for opinions;”</li>
        <br />
        <li>Grant/cash money making schemes;</li>
        <br />
        <li>
          Pharmaceutical informational sites or any site that offers information
          or services in relation to the purchasing of pharmaceutical drugs;
        </li>
        <br />
        <li>
          Any website that is in violation of the credit card association rules;
        </li>
        <br />
        <li>Credit protection/identity theft protection.</li>
        <br />
      </ul>
      <ol>
        <li>
          Subbill will only process for escort sites if the site is acting in a
          directory (i.e., phonebook) capacity. Subbill will not process for
          websites selling escort services.
        </li>
        <br />
        <li>
          Using a sub-account and its signup form for a site that has been
          approved to process for another URL that is not registered to that
          sub-account and approved by a credit card association.
        </li>
        <br />
        <li>
          “Posting in” or any other violation of credit card associations'
          rules.
        </li>
        <br />
        <li>
          Violations of credit card association rules may result in the
          immediate termination of all services by Subbill including recurring
          billing. Subbill will hold all funds until all credit card association
          fines, chargebacks, and refunds have been satisfied.
        </li>
      </ol>
      <h2 className="page__block-title"> 4. Subbill's Right to Cancel</h2>
      If the merchant's account is suspended for unacceptable conduct or
      suspicion of fraud, all rebilling members may be cancelled and all money
      held for a period of six months to one year. In addition, Subbill may,
      where feasible, implement technical mechanisms, which prevent the
      occurrences listed above. Subbill is not required to notify the merchant
      of its actions.
      <br />
      <br />
      Subbill may deactivate Subbill accounts or sub-accounts that have not
      processed any transactions within a 90-day period or the Subbill join page
      is not viewable from the home URL of the venue. Subbill deactivates
      accounts to minimize the unnecessary operational cost associated with
      maintaining them in the system.
      <br />
      Compliance with Rules of Other Networks
      <br />
      Any access to other networks connected to Subbill's Internet service must
      comply with the rules for that network as well as with Subbill's rules.
      <br />
      <br />
      <h2 className="page__block-title">Monitoring/Privacy</h2>
      Subbill may, without your permission, monitor any communications through
      or with its facilities as well as all the merchant's sites for compliance
      with this AUP and the merchant services agreement. Subbill may also be
      required to provide access to the merchant's websites to representatives
      of the credit card associations or their acquiring members for monitoring
      for compliance with their operating rules. The merchant agrees that
      Subbill is not considered a secure communications medium for the purposes
      of the Electronic Communications Privacy Act, and that no expectation of
      privacy is afforded to it. It may become necessary for Subbill's employees
      to examine system accounting logs and other records to determine if
      privacy violations or other network unfriendly activities have occurred.
      <br />
      <br />
      <h2 className="page__block-title">Cooperation with Authorities</h2>
      Subbill will cooperate with law enforcement and other authorities in
      investigating claims of illegal activity, including illegal transfer or
      availability of copyrighted material, trademarks, child pornography,
      postings or email containing threats of violence, or other illegal
      activity.
      <br />
      <br />
      <h2 className="page__block-title">
        Confidentiality of Personal Subscriber Information
      </h2>
      Subbill will not release any merchant or customer personal subscriber
      information, nor merchant or customer billing information, to any third
      party except on presentation of a valid court order, or request to which
      Subbill is legally required to respond to. The merchant agrees that
      Subbill's judgement as to the validity of any court order, subpoena, or
      request will be considered proper and final.
      <br />
      <br />
      <h2 className="page__block-title">Right to Change AUP</h2>
      Subbill may change this AUP at any time. It is your responsibility to
      regularly review the AUP on the Subbill website to ensure compliance with
      the latest version of this AUP. Your use of Subbill's services after
      Subbill has posted the changes to the AUP constitutes your acceptance of
      the changes to this AUP. Subbill hopes the AUP is helpful in clarifying
      the obligations of Internet users, including Subbill and its merchants, as
      responsible members of the Internet. Any complaints about a merchant's
      violation of the AUP should be sent to sales@subbill.net.
    </Page>
  );
}

export default AcceptPolicy;
