import React from "react";
import cn from "classnames";

import "./Page.scss";

function Page({ children, title, className }) {
  return (
    <div className={cn("page", className)}>
      {title && <h1 className="page__title">{title}</h1>}
      {children}
    </div>
  );
}

export default Page;
