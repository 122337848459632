import React from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";

function AbutUs() {
  const { t } = useTranslation();

  return (
    <Page title={t("about_as.title")}>
      <h2 className="page__block-title">{t("about_as.text_1.title")}</h2>
      <p className="page__block-text">{t("about_as.text_1.text")}</p>
      <h2 className="page__block-title">{t("about_as.text_2.title")}</h2>
      <p className="page__block-text">{t("about_as.text_2.text_1")}</p>
      <br />
      <p className="page__block-text">{t("about_as.text_2.text_2")}</p>
      <br />

      <p className="page__block-text">{t("about_as.text_2.text_3")}</p>
      <br />

      <p className="page__block-text">{t("about_as.text_2.text_4")}</p>
      <br />

      <p className="page__block-text">{t("about_as.text_2.text_5")}</p>
      <br />

      <p className="page__block-text">{t("about_as.text_2.text_6")}</p>
      <br />
    </Page>
  );
}

export default AbutUs;
