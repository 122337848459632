export const menu = {
  cunsumer: [{ title: "header.contact_us", href: "/questions" }],
  merchant: [
    { title: "header.home", href: "/", exact: true },
    { title: "header.about_us", href: "/about" },
    { title: "header.services", href: "/service" },
    { title: "header.merchant_login", href: "/login" },
    { title: "header.contact", href: "/contacts" },
  ],
};
