import React from "react";

import { Page } from "../../components/Page";

import Man from "./assets/man.svg";
import Support from "./assets/support.svg";
import Logo from "./assets/logo.png";
import "./Home.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();

  return (
    <Page className="home-merchant">
      <div className="row">
        <div className="col-md-6">
          <h1 className="home-merchant__title">{t("home.merchant.title")}</h1>
          <p className="home-merchant__text">{t("home.merchant.text")}</p>
        </div>
        <div className="col-md-5 col-xl-4 offset-xl-2 offset-md-1">
          <div className="card">
            <img className="card__img" src={Man} alt="" />
            <h2 className="card__title">{t("card_1.title")}</h2>
            <p className="card__text">{t("card_1.text")}</p>
            <Link className="card__button" to="/app-form">
              {t("card_1.button")}
            </Link>
          </div>
          <div className="card">
            <img className="card__img" src={Support} alt="" />
            <h2 className="card__title">{t("card_2.title")}</h2>
            <p className="card__text">{t("card_2.text")}</p>
            <Link className="card__button" to="/contacts">
              {t("card_2.button")}
            </Link>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-lg-4">
          <div className="home-merchant__card">
            <h4 className="home-merchant__card-title">
              {t("home.merchant.card_1.title")}
            </h4>
            <p className="home-merchant__card-text">
              <img src={Logo} alt="" />- {t("home.merchant.card_1.text")}
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="home-merchant__card">
            <h4 className="home-merchant__card-title">
              {t("home.merchant.card_2.title")}
            </h4>
            <p className="home-merchant__card-text">
              {t("home.merchant.card_2.text")}
            </p>
            <Link className="home-merchant__card-link" to="/service">
              {t("read_more")}
            </Link>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="home-merchant__card">
            <h4 className="home-merchant__card-title">
              {t("home.merchant.card_3.title")}
            </h4>
            <p className="home-merchant__card-text">
              {t("home.merchant.card_3.text")}
            </p>
            <Link className="home-merchant__card-link" to="/contacts">
              {t("read_more")}
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Home;
