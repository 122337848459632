import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Page } from "../../components/Page";
import "./Contact.scss";

function Contact() {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Page className="contact">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-4 control">
            <label htmlFor="name">{t("field.full_name")}:</label>
            <input ref={register({})} name="name" type="text" />
          </div>
          <div className="col-md-4 control">
            <label htmlFor="email">{t("field.email")}:</label>
            <input ref={register({})} name="email" type="text" />
          </div>
          <div className="col-md-4 control">
            <label htmlFor="subject">{t("field.subject")}:</label>
            <input ref={register({})} name="subject" type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12 control">
            <label htmlFor="message">{t("field.message_text")}:</label>
            <textarea name="message" />
          </div>
          <div className="col-md-3">
            <button type="submit">{t("field.submit")}</button>
          </div>
        </div>
      </form>
    </Page>
  );
}

export default Contact;
