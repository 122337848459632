import React from "react";
import { Page } from "../../components/Page";

function PrivacyPolicy() {
  return (
    <Page title="Merchant Privacy Policy">
      Eurobill Tech Ltd, formerly CentroBill (Cyprus) Limited (the “Company”)
      respects your privacy and is committed to protecting it through this
      privacy policy (the “policy”). This policy describes the types of
      information the Company may collect from you or that you may provide in
      connection with the Subbill Internet billing services located at
      b2b.subbill.net (the “Website”) and the Company's practices for
      collecting, using, keeping, protecting, and disclosing that information.
      <br />
      <br />
      This policy applies to information the Company collects: On the Website
      (including desktop and mobile versions). In email, text, and other
      electronic messages between you and the Company. When you interact with
      the Company's advertising and applications on third-party websites and
      services, if those applications or advertising include links to this
      policy. When you interact with the Website through third-party social
      media platforms.
      <br />
      <br />
      It does not apply to information collected by: the Company offline or
      through any other means, including on any other website operated by the
      Company or any third party (including the Company's affiliates and
      subsidiaries); or any third party (including the Company's affiliates and
      subsidiaries), including through any application or content (including
      advertising) that may link to or be accessible from (or on) the Website.
      <br />
      <br />
      Important: Please read this policy carefully to understand the Company's
      policies and practices regarding your information and how the Company will
      treat it. If you do not agree with the Company's policies and practices,
      your choice is not to use the Website. By accessing or using the Website,
      you agree to this policy and consent to the Company's collection, use,
      disclosure, retention, and protection of your personal information as
      described in this policy. The Company may change this policy on one or
      more occasions. The Company will consider your continued use of the
      Website after the Company makes changes as your acceptance of the changes,
      so please check this policy frequently for updates.
      <br />
      <br />
      <h2 className="page__block-title">1. Are minors welcome?</h2> The Website
      is not intended for persons under 18-years old. You will only access the
      Website if (1) you are at least 18-years old and (2) have reached the age
      of majority where you live. The Company prohibits all persons who do not
      meet the age requirements from accessing the Website. Minors must not
      access the Website or use its services.
      <br />
      <br />
      The Company does not knowingly collect any information about children,
      minors, or anyone under the age of majority. Nor does the Company
      knowingly market to children, minors, or anyone under 18-years old. If you
      are under 18-years old, you must not submit information to the Company and
      must leave the Website. If the Company learns that it has collected
      personal information from a child, minor, or anyone under 18-years old,
      the Company will delete that information as quickly as possible.
      <br />
      <br />
      Legal Notice: In accordance with 47 U.S.C. § 230(d), you are notified that
      parental control protections (including computer hardware, software, or
      filtering services) are commercially available that may assist in limiting
      access to material that is harmful to minors. You may find information
      about providers of these protections on the Internet by searching
      “parental control protection” or similar terms. If minors have access to
      your computer, please restrain their access to sexually explicit material
      by using any of the following products, which the Company provides for
      informational purposes only and does not endorse: CYBERsitter™ | Net
      Nanny® | CyberPatrol | ASACP.
      <br />
      <br />
      <h2 className="page__block-title">
        2. What types of information does the Company collect about you and how
        is it collected?
      </h2>
      The Company may collect several types of information from and about users
      of the Website, including information: by which you may be personally
      identified, including your full name, email address, company name, website
      URL, or any other information that the Company collects that is defined as
      personal or personally identifiable information under law (“personal
      information”); that is about you but individually does not identify you,
      including your location or any other optional information you provide
      about yourself; or about your Internet connection, the equipment you use
      to access the Website, and usage details.
      <br />
      <br />
      The Company collects this information: Directly from you when you provide
      it to the Company. Automatically as you navigate through the Website.
      Information collected automatically may include usage details, IP
      addresses, and information collected through cookies, web beacons, and
      other tracking technologies. From third parties, for example, the
      Company's business partners.
      <br />
      <br />
      Information You Provide to the Company The information the Company
      collects on or through the Website may include: Information that you
      provide by filing in forms on the Website. This includes information
      provided at the time of applying for an account or requesting further
      services. The Company may also ask you for information when you report a
      problem with the Website or a service. Records and copies of your
      correspondence (including email addresses), if you contact the Company.
      Your responses to surveys that the Company might ask you to complete for
      research purposes. You may be required to provide financial information
      before receiving payment from the Company. Your search queries on the
      Website.
      <br />
      <br />
      Information the Company Collects through Automatic Data Collection
      Technologies As you navigate through and interact with the Website, the
      Company may use automatic data collection technologies to collect certain
      information about your equipment, browsing actions, and patterns,
      including: Details of your visits to the Website, including traffic data,
      location data, logs, and other communication data and the resources that
      you access and use on the Website. Information about your computer and
      Internet connection, including your IP address, operating system, and
      browser type. Information about your mobile device and Internet
      connection, including the device's unique device identifier, IP address,
      operating system, browser type, mobile network information, and the
      device's telephone number.
      <br />
      <br />
      The Company also may use these technologies to collect information about
      your online activities over time and across third-party websites or other
      online services (behavioral tracking).
      <br />
      <br />
      The information the Company collects automatically is statistical data and
      does not include personal information, but the Company may keep it or
      associate it with personal information the Company collects in other ways
      or receive from third parties. It helps the Company to improve the Website
      and to deliver a better and more personalized service, including by
      allowing the Company to: Estimate the Website's audience size and usage
      patterns. Store information about your preferences, thus allowing the
      Company to customize the Website according to your individual interests.
      Speed up your searches. Recognize you when you return to the Website.
      <br />
      <br />
      The technologies the Company uses for this automatic data collection may
      include: Cookies (or browser cookies). A cookie is a small file placed on
      the hard drive of your computer. Your browser stores cookies in a manner
      associated with each website you visit. The Company uses cookies to enable
      its servers to recognize your browser and tells the Company how and when
      you visit the Website and use the services. You may refuse to accept
      browser cookies by activating the appropriate setting on your browser. But
      if you select this setting you may be unable to access certain parts of
      the Website. Unless you have adjusted your browser settings so that it
      will refuse cookies, the Company's system will issue cookies when you
      direct your browser to the Website. If you want to learn more about
      cookies, please visit www.allaboutcookies.org or www.youronlinechoices.eu.
      Flash Cookies. Certain features of the Website may use local stored
      objects (or Flash cookies) to collect and store information about your
      preferences and navigation to, from, and on the Website. Flash cookies are
      not managed by the same browser settings as are used for browser cookies.
      For information about managing your privacy and security settings for
      Flash cookies, you can access your Flash management tools from Adobe's
      website. Web Beacons. Pages of the Website (and the Company's emails) may
      contain small electronic files known as web beacons (also referred to as
      clear gifs, pixel tags, and single-pixel gifs) that permit the Company,
      for example, to count users who have visited those pages (or opened an
      email) and for other related website statistics (for example, recording
      the popularity of certain website content and verifying system and server
      integrity). If you want to learn more about web beacons, please visit
      www.allaboutcookies.org/web-beacons/ .
      <br />
      <br />
      The Company does not collect personal information automatically, but it
      may tie this information to personal information about you that the
      Company collects from other sources or you provide to the Company.
      <br />
      <br />
      <h2 className="page__block-title">
        3. How does the Company use your information?
      </h2>
      The Company may use information that it collects about you or that you
      provide to the Company, including any personal information: To present the
      Website and its contents to you. To provide you with information,
      products, or services that you request from the Company. To fulfill any
      other purpose for which you provide it. To provide you with notices about
      your account. To carry out the Company's obligations and enforce its
      rights arising from any contracts entered into between you and the
      Company, including to receive payment from the Company. To notify you
      about changes to the Website or any products or services the Company
      offers or provides through the Website. To allow you to participate in any
      interactive features on the Website. To monitor and analyze trends, usage,
      and activities in connection with the Website and for marketing or
      advertising purposes. To investigate and prevent fraudulent transactions,
      unauthorized access to the Website, and other illegal activities. To
      personalize the Website content, features, or advertisements. In any other
      way the Company may describe when you provide the information. For any
      other purpose with your consent.
      <br />
      <br />
      The Company may also use your information to contact you about the
      Company's own and third parties' goods and services that may be of
      interest to you. If you do not want the Company to use your information in
      this way, please send the Company an email at support@subbill.net. For
      more information, see What choices do you have about how the Company uses
      and discloses your information.
      <br />
      <br />
      <h2 className="page__block-title">
        4. With whom does the Company share your information?{" "}
      </h2>
      The Company may disclose aggregated information about its users, and
      information that does not identify any individual, without restriction.
      <br />
      <br />
      The Company may disclose personal information that it collects or you
      provide as described in this policy: To the Company's subsidiaries and
      affiliates. To contractors, service providers, and other third parties the
      Company uses to support its business and who are bound by contractual
      obligations to keep personal information confidential and use it only for
      the purposes for which the Company discloses it to them. To affiliated
      merchant banks and credit card associations in order to process a payment
      and provide the services to you. To banking partners as required by credit
      card association rules for inclusion on their list of terminated merchants
      (in the event that you meet their criteria which includes having the
      Company close your account due to your breach of the merchant services
      agreement). To a buyer or other successor in the event of a merger,
      divestiture, restructuring, reorganization, dissolution, or other sale or
      transfer of some or all of the Company's assets, whether as a going
      concern or as part of bankruptcy, liquidation, or similar proceeding, in
      which personal information held by the Company about the Website's users
      is among the assets transferred. To third parties to market their products
      or services to you if you have not opted out of these disclosures. The
      Company contractually requires these third parties to keep personal
      information confidential and use it only for the purposes for which the
      Company discloses it to them. For more information, see What choices do
      you have about how the Company uses and discloses your information. To
      fulfill the purpose for which you provide it. For any other purpose
      disclosed by the Company when you provide the information. With your
      consent.
      <br />
      <br />
      The Company may also disclose your personal information:
      <br />
      <br />
      To comply with any court order, law, or legal process, including to
      respond to any government or regulatory request. To enforce the merchant
      services agreement, including to receive payment from the Company. If the
      Company believes disclosure is necessary or appropriate to protect the
      rights, property, or safety of the Company, its customers, or others. This
      includes exchanging information with other companies and organizations for
      the purposes of fraud protection and credit risk reduction.
      <br />
      <br />
      <h2 className="page__block-title">
        5. What choices do you have about how the Company uses and discloses
        your information?
      </h2>{" "}
      The Company provides you the ability to exercise certain controls and
      choices regarding its collection, use, and sharing of your information. In
      accordance with local law, your controls and choices may include: You may
      correct, update, and delete your account information. You may change your
      choices for subscriptions and newsletters. You may choose whether or not
      to receive offers from the Company. You may choose whether you received
      targeted advertising from the Company or its partners.
      <br />
      <br />
      <h2 className="page__block-title">
        6. How do you access and correct your information?
      </h2>{" "}
      You can help ensure that your contact information and preferences are
      accurate, complete, and up to date by logging in to your account at{" "}
      <a href="https://clients.subbill.net/">https://clients.subbill.net/</a>
      or by emailing the Company at support@subbill.net. For other personal
      information that the Company holds, the Company will provide you with
      access for any purpose including to request that the Company correct the
      data if it is inaccurate or delete the data if the Company is not required
      to retain it by law or for legitimate business purposes. The Company may
      decline to process requests that are frivolous/vexatious, jeopardize the
      privacy of others, are extremely impractical, or for which access is not
      otherwise required by local law.
      <br />
      <br />
      <h2 className="page__block-title">
        7. How does the Company protect your personal information?
      </h2>{" "}
      The Company has implemented measures designed to secure your personal
      information from accidental loss and from unauthorized access, use,
      change, and disclosure. All information you provide to the Company is
      stored on its secure servers behind firewalls. The Company encrypts all
      payment transactions using SSL technology.
      <br />
      <br />
      The safety and security of your information also depends on you. Where the
      Company has given you (or where you have chosen) a password for access to
      certain parts of the Website, you are responsible for keeping this
      password confidential. The Company asks you not to share your password
      with anyone.
      <br />
      <br />
      No Security Guarantees: Although the Company takes appropriate measures to
      safeguard against unauthorized disclosures of information, the Company
      cannot assure you that information will never be disclosed, altered, or
      destroyed in a manner that is inconsistent with this policy.
      <br />
      <br />
      <h2 className="page__block-title">
        8. Where is your personal information kept?
      </h2>
      This policy is intended to cover collection of information on or through
      the Website from residents of the Netherlands. If you are visiting the
      Website from outside the Netherlands, please be aware that your
      information may be transferred to, stored, and processed in the
      Netherlands where the Company's servers are located and the Company's
      central database is operated. The data protection and other laws of the
      Netherlands and other countries might not be as comprehensive as those in
      your country. Please be assured that the Company seeks to take reasonable
      steps to make sure that your privacy is protected. By using the Company's
      services, you acknowledge that your information may be transferred to the
      Company's facilities and those third parties with whom the Company shares
      it as described in this policy. When you provide personal information to
      the Company through the Website, you consent to the processing of your
      data in, and the transfer of your data to, the Netherlands or any other
      country in which the Company or its affiliates, subsidiaries, or service
      providers host these services.
      <br />
      <br />
      <h2 className="page__block-title">9. Do Not Track Policy</h2>
      Do Not Track (“DNT”) is a privacy preference that you can set in your
      browser. DNT is a way for you to inform websites and services that you do
      not want certain information about your webpage visits collected over time
      and across websites or online services. The Company is committed to
      providing you with meaningful choices about the information it collects
      and that is why the Company provides you the ability to opt out. But the
      Company does not recognize or respond to any DNT signals as the Internet
      industry works toward defining exactly what DNT means, what it means to
      comply with DNT, and a common approach to responding to DNT. For more
      information, visit www.allaboutdnt.com.
      <br />
      <br />
      <h2 className="page__block-title">
        10. Your California Privacy Rights
      </h2>{" "}
      California Civil Code § 1798.83 permits users of the Website that are
      California residents to request certain information about the Company's
      disclosure of personal information to third parties for their direct
      marketing purposes. To make this request, please send an email to
      support@subbill.net.
      <br />
      <br />
      Further, if you are a California resident and would like to opt out from
      the disclosure of your personal information to any third party for
      marketing purposes, please send an email to support@subbill.net. Please be
      advised that if you opt out from permitting your personal information to
      be shared, you may still receive selected offers directly from the Company
      in accordance with California law.
      <br />
      <br />
      <h2 className="page__block-title">11. Your Canada Privacy Rights</h2> The
      Company complies with the Canadian Federal and Provincial privacy laws and
      regulations including the Personal Information Protection and Electronic
      Documents Act.
      <br />
      <br />
      The Company will only use your personal information for the purposes
      intended and as detailed in this policy unless the Company has obtained
      your consent to use it for other purposes.
      <br />
      <br />
      Residents of Canada are notified that the personal information they
      provide to the Company may be stored in the Company's databases outside of
      Canada, including in the Netherlands, and may be subject to disclosure to
      authorized law enforcement or government agencies in response to lawful
      demand under the laws of that country.
      <br />
      <br />
      If you need to contact the Company about your personal information or
      believe that the Company has violated your privacy rights, please email
      the Company at support@subbill.net. You may visit www.priv.gc.ca for more
      information about your privacy rights.
      <br />
      <br />
      <h2 className="page__block-title">12. Links to Other Websites</h2> The
      Website contains links to other websites. Please be aware that the Company
      is not responsible for the content or privacy practices of those other
      websites. The Company encourages its customers to be aware when they leave
      the Website and to read the privacy statements of any other website that
      collects personally identifiable information.
      <br />
      <br />
      <h2 className="page__block-title">13. No Rights of Third Parties</h2> This
      policy does not create rights enforceable by third parties or require
      disclosure of any personal information relating to users of the Website.
      <br />
      <br />
      <h2 className="page__block-title">14. Changes to Privacy Policy</h2> The
      Company will post any changes it makes to this policy on this page. If the
      Company makes material changes to how it treats your personal information,
      the Company will notify you by email to the email address identified in
      your account or through a notice on the Website homepage. This policy
      identifies when the Company last updated it at the top of the page. You
      are responsible for making sure the Company has an up-to-date active and
      deliverable email address for you, and for frequently visiting the Website
      and this policy to check for any changes.
      <br />
      <br />
      <h2 className="page__block-title">15. Contact Information</h2> To ask
      questions or comment about this policy and the Company's privacy
      practices, contact the Company at support@subbill.net.
    </Page>
  );
}

export default PrivacyPolicy;
